import styled from 'styled-components';
import Quote from 'components/UI/Quote/Quote';
import Text from 'components/UI/Text/Text';


export const StyledQuote = styled.blockquote`
  position: relative;
  display: block;
  padding: 0 70px;
  font-style:italic;
  

  &::before,
  &::after {
    content: '';
    position: absolute;
    display: block;
    background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0%0APSIyNCI+PHBhdGggZD0iTTYgMTdoM2wyLTRWN0g1djZoM3ptOCAwaDNsMi00VjdoLTZ2NmgzeiIv%0APjxwYXRoIGQ9Ik0wIDBoMjR2MjRIMHoiIGZpbGw9Im5vbmUiLz48L3N2Zz4=) no-repeat center;
    background-size: 50px;
    height: 50px;
    width: 50px;
  }

  &::before {
    left: 0;
    top: 0;
    transform: rotateZ(180deg);

  }

  &::after {
    right: 0;
    bottom: 0;
  }
`;
