import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const StyledPostImg = styled.div`
  width: 100%;
  height: 376px;
  margin-bottom: 30px;

  background: url(${({ src }) => src}) no-repeat center;
  background-size: contain;

  ${MEDIA.TABLET`
    width: 350px;
    height: 312px;
    margin-left: 10px;
    float: right;
  `}
`;
