import React from 'react';
import { INLINES, BLOCKS, MARKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import Text from 'components/UI/Text/Text';
import Quote from 'components/UI/Quote/Quote';
import { StyledPostImg } from './styled';
import { StyledQuote } from '../Quote/styled';

const HEADINGS = [1,2,3,4,5,6].map(i => BLOCKS[`HEADING_${i}`]);

export default function({
  richTextAST,
  headingsProps,
  contentProps,
  contentImageUrl,
  ...forceProps
}) {
  let needAddContentImage = !!contentImageUrl;
  const render = (nodeType, { children, node }, nodeProps) => {
    if (nodeType === BLOCKS.EMBEDDED_ASSET) {
      const { file, title } = node.data.target.fields;
      const { url } = file['en-US'];

      return <img src={url} alt={title} style={{ maxWidth: '100%' }} />;
    }

    if (nodeType === BLOCKS.QUOTE) {
      return <Quote>{children}</Quote>
    }

    const isHeader = HEADINGS.includes(nodeType);
    const props = {
      ...nodeProps,
      ...forceProps,
      ...(isHeader ? headingsProps : contentProps),
    };
    const { stack } = props;

    if (!isHeader && needAddContentImage) {
      children.unshift(<StyledPostImg src={contentImageUrl} />);
      needAddContentImage = false;
    }

    if (!children[0]) {
      return null
    }

    return (<Text {...props} stack={stack}>{children}</Text>);
  };

  const options = {
    renderNode: {
      [BLOCKS.HEADING_1]: (node, children) =>
        render(BLOCKS.HEADING_1, { children }, { as: 'h3' }),
      [BLOCKS.HEADING_2]: (node, children) =>
        render(BLOCKS.HEADING_2, { children }, { variant: 'subtitle' }),
      [BLOCKS.HEADING_3]: (node, children) =>
        render(BLOCKS.HEADING_3, { children }, { bold: true }),
      [BLOCKS.HEADING_4]: (node, children) =>
        render(BLOCKS.HEADING_4, { children }, { bold: true }),
      [BLOCKS.HEADING_5]: (node, children) =>
        render(BLOCKS.HEADING_5, { children }, { bold: true }),
      [BLOCKS.HEADING_6]: (node, children) =>
        render(BLOCKS.HEADING_6, { children }, { bold: true }),
      [BLOCKS.PARAGRAPH]: (node, children) =>
        render(BLOCKS.PARAGRAPH, { children }, { variant: 'desc' }),
      [BLOCKS.UL_LIST]: (node, children) =>
        render(BLOCKS.UL_LIST, { children }, { as: 'ul' }),
      [BLOCKS.OL_LIST]: (node, children) =>
        render(BLOCKS.OL_LIST, { children }, { as: 'ol' }),
      [BLOCKS.LIST_ITEM]: (node, children) =>
        render(BLOCKS.LIST_ITEM, { children }, { as: 'li' }),
      [BLOCKS.QUOTE]: (node, children) =>
        render(BLOCKS.QUOTE, { children }, {}),
      [BLOCKS.EMBEDDED_ASSET]: (node, children) =>
        render(BLOCKS.EMBEDDED_ASSET, { children, node }, { variant: 'desc' }),
      [INLINES.HYPERLINK]: (node, children) => (
        <a href={node.data.uri} target="_blank">{children}</a>
      ),
    },
    renderMark: {
      [MARKS.BOLD]: text => (<Text bold as="span">{text}</Text>),
    },
  };

  return documentToReactComponents(richTextAST, options);
}
